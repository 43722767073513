* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  list-style: none;
}

body {
  scroll-snap-type: y mandatory;
  background-color: #94ec6d;
}

.container {
  background-color: #0d141a;
  border-radius: 8px;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.navbar {
  backdrop-filter: blur(12px);
  background-color: #0000;
}

.navbar .nav-link {
  color: #0d141a;
  background-color: #94ec6d;
  border: 1px solid #94ec6d;
  border-radius: 8px;
  gap: 2px;
  padding: 8px;
  transition: all .2s ease-in;
}

.navbar .nav-link:hover {
  background-color: #f23838;
  border: 1px solid #f23838;
  border-radius: 4px;
}

.navbar-brand {
  color: #0d141a;
  font-family: Medula One, system-ui;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
}

.navbar-brand:hover {
  color: #f23838;
}

.banner {
  background-image: url("banner-snake.1e39bbbf.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.banner h1 {
  color: #f23838;
  font-family: Medula One, system-ui;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 500;
}

.banner .lead {
  font-family: Medula One, system-ui;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
}

.instrucoes {
  color: #fff;
  border-radius: 8px;
  margin: 0 2rem 8px;
  text-decoration: none;
}

.instrucoes h2 {
  color: #f23838;
  font-family: Medula One, system-ui;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
}

.instrucoes p {
  padding: 0 2rem;
  font-size: 1.2rem;
}

#grid-container {
  background-color: #27900d;
  border: 1px solid #27900d;
  grid-template-rows: repeat(20, 1.7rem);
  grid-template-columns: repeat(20, 1.7rem);
  gap: 1px;
  margin: 1rem auto;
  display: grid;
  position: relative;
}

#game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cell {
  background-color: #27900d;
  border: 1px solid #27900d;
  border-radius: 1px;
  width: 1.7rem;
  height: 1.7rem;
}

.cell.alive {
  background-image: url("images/cobra.png");
  background-size: cover;
  border: 1px solid #7b200f;
  border-radius: 4px;
}

.cell.food {
  background-image: url("maça.0d97628f.png");
  background-size: cover;
}

#restart-button, #start-button, #soundOff {
  color: #000;
  background-color: #f23838;
  border: 1px solid #f23838;
  border-radius: 6px;
  width: 18rem;
  margin: 12px auto;
  padding: 12px;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 0 10px #b10b0b99;
}

#restart-button:hover, #start-button:hover, #soundOff:hover {
  color: #fff;
  background-color: #0000;
  border: 1px solid #f23838;
}

.placar {
  backdrop-filter: blur(12px);
  color: #fff;
  text-align: center;
  background-color: #0000;
  border: 1px solid #f23838;
  border-radius: 6px;
  width: 32vw;
  margin: 0 auto;
  font-size: 1.2rem;
  font-weight: 700;
  box-shadow: 0 0 10px #b10b0b99;
}

.placar #score-container, .placar #highscore-container, .placar #game-over-score {
  margin: 5px;
  font-size: 20px;
}

#game-over-score {
  color: #fff;
  text-align: center;
  text-shadow: 0 0 10px #ffffff80;
  font-size: 1.6rem;
}

@media (width <= 768px) {
  #grid-container {
    display: none;
  }

  .banner {
    background-image: url("banner-snake.1e39bbbf.png");
    background-position: center;
    background-size: cover;
  }
}

.ad h2 {
  color: #f23838;
  font-family: Medula One, system-ui;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
}

.ad p {
  color: #fff;
  font-size: 1.1rem;
}

.ad .btn {
  color: #000;
  background-color: #f23838;
  border: 1px solid #f23838;
  border-radius: 6px;
  width: 18rem;
  padding: 12px;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 0 10px #b10b0b99;
}

.ad .btn:hover {
  color: #fff;
  background-color: #0000;
  border: 1px solid #f23838;
}

.footer {
  text-align: center;
  color: #e4e4e4;
  border-radius: 12px 12px 0 0;
  justify-content: center;
  align-items: center;
}

.footer a {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
}

.footer__title {
  border-top: 1px solid #a8a8a8;
  margin: 0 auto;
  padding-top: 12px;
  font-size: 1rem;
}

.footer__copy {
  color: #e4e4e4;
  max-width: 100%;
  font-size: 1rem;
}

.footer__social-links {
  justify-content: center;
  margin-top: 1rem;
  display: flex;
}

.footer__social-links li {
  margin-right: 1rem;
}

.footer__social-links li img {
  width: 30px;
  height: 30px;
  transition: transform .3s;
}

.footer__social-links li img:hover {
  transform: scale(1.3);
}

.level-up {
  color: #fff;
  text-align: center;
  z-index: 1000;
  background-color: #000c;
  border-radius: 10px;
  padding: 20px 40px;
  animation: 3s forwards fadeInOut;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.countdown {
  color: #fff;
  text-align: center;
  z-index: 1000;
  background-color: #000c;
  border-radius: 10px;
  padding: 20px 40px;
  font-size: 3rem;
  animation: 3s forwards fadeInOut;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.1daa0aa9.css.map */
