/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

body {
    background-color: #94ec6d;
    scroll-snap-type: y mandatory;
}

/* Estilos para o container */
.container {
    display: flex;
    flex-direction: column;
    background-color: #0D141A;
    padding: 20px;
    border-radius: 8px;
}

.navbar {
    background-color: transparent;
    backdrop-filter: blur(12px);
    

    .nav-link {
        color: #0D141A;
        border-radius: 8px;
        padding: 8px;
        background-color: #94ec6d;
        transition: ease-in 0.2s;
        border: 1px solid #94ec6d;
        gap: 2px;
        &:hover {
            border: 1px solid #f23838;
            background-color: #f23838;
            border-radius: 4px;

        }

    }
}
.navbar-brand {
    font-family: "Medula One", system-ui;
        font-weight: 500;
        font-style: normal;
        font-size: 1.7rem;
        color: #0D141A;
        &:hover {
            color:#f23838;
        }
}




/* Estilos para o hero */
.banner {
    background-image: url(../images/banner-snake.png);
    background-size: 100%;
    background-repeat: no-repeat;

    h1 {
        font-family: "Medula One", system-ui;
        font-weight: 500;
        font-style: normal;
        font-size: 4.5rem;
        color: #f23838;
    }

    .lead {
        font-family: "Medula One", system-ui;
        font-weight: 500;
        font-style: normal;
        font-size: 2rem;
    }
}

/* Estilos para as instruções */
.instrucoes {
    color: #ffffff;
    text-decoration: none;
    margin: 0 2rem;
    margin-bottom: 8px;
    border-radius: 8px;

    h2 {
        font-family: "Medula One", system-ui;
        font-weight: 500;
        font-style: normal;
        font-size: 3rem;
        color: #f23838;
        
    }

    p {
        font-size: 1.2rem;
        padding: 0 2rem;
    }
}

/* Estilos para o grid-container */
#grid-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(20, 1.7rem);
    grid-template-rows: repeat(20, 1.7rem);
    gap: 1px;
    margin: 1rem auto;
    background-color: #27900D;
    border: 1px solid #27900D;
}

/* Estilos para o game-over */
#game-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Estilos para as células */
.cell {
    width: 1.7rem;
    height: 1.7rem;
    background-color: #27900D;
    border: 1px solid #27900D;
    border-radius: 1px;
}

.cell.alive {
    background-image: url(../images/cobra.png);
    background-size: cover;
    border-radius: 4px;
    border: 1px solid #7B200F;
}

.cell.food {
    background-image: url(../images/maça.png);
    background-size: cover;
}

/* Estilos para o botão de restart */
#restart-button,
#start-button,
#soundOff {
    width: 18rem;
    margin: 12px auto;
    padding: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    border-radius: 6px;
    background-color: rgb(242, 56, 56);
    box-shadow: 0 0 10px rgba(177, 11, 11, 0.6);
    border: 1px solid rgb(242, 56, 56);

    &:hover {
        background-color: transparent;
        border: 1px solid rgb(242, 56, 56);
        color: white;
    }
}

/* Estilos para o placar */
.placar {
    background-color: transparent;
    backdrop-filter: blur(12px);
    width: 32vw;
    margin: 0 auto;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(177, 11, 11, 0.6);
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem;
    border: 1px solid rgb(242, 56, 56);

    #score-container,
    #highscore-container,
    #game-over-score {
        margin: 5px;
        font-size: 20px;
    }
}

#game-over-score {
    color: #ffffff;
    text-align: center;
    font-size: 1.6rem;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Estilos para elementos ocultos */
.hidden {
    display: none;
}

@media (max-width: 768px) {
    #grid-container {
        display: none;
    }

    .banner {
        background-image: url(../images/banner-snake.png);
        background-size: cover;
        background-position: center;
    }
}

.ad {
    h2 {
        font-family: "Medula One", system-ui;
        font-weight: 500;
        font-style: normal;
        font-size: 3rem;
        color: #f23838;
    }
    p {
        font-size: 1.1rem;
        color: #ffffff;
    }

    .btn {
        width: 18rem;

    padding: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    border-radius: 6px;
    background-color: rgb(242, 56, 56);
    box-shadow: 0 0 10px rgba(177, 11, 11, 0.6);
    border: 1px solid rgb(242, 56, 56);

    &:hover {
        background-color: transparent;
        border: 1px solid rgb(242, 56, 56);
        color: white;
    }
    }
}

.footer {
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 12px 12px 0 0;
    color: rgb(228, 228, 228);

    a {
        text-decoration: none;
        font-size: 1rem;
        font-weight: 500;
        color: white;
    }

    &__title {
        font-size: 1rem;
        padding-top: 12px;
        margin: 0 auto;
        border-top: 1px solid #a8a8a8;

    }

    &__copy {
        font-size: 1rem;
        color: rgb(228, 228, 228);
        max-width: 100%;
    }

    &__social-links {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        li {
            margin-right: 1rem;
            img {
                width: 30px;
                height: 30px;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.3);
                }
            }
        }
    }
}

.level-up {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    text-align: center;
    z-index: 1000;
    animation: fadeInOut 3s forwards;
}

.countdown {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    text-align: center;
    z-index: 1000;
    animation: fadeInOut 3s forwards;
    font-size: 3rem;
}

.hidden {
    display: none;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}